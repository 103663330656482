import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { createUploadLink } from 'apollo-upload-client';

import introspection from '@/graphql';
import { getAuthToken, removeAuthToken } from '@/helpers/auth';

const removeTypenameLink = removeTypenameFromVariables();

const httpLink = createUploadLink({
  uri: `${import.meta.env.WEBSITE_API_URL ?? '/graphql'}`,
});

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = getAuthToken();

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    const error = networkError as ServerError | ServerParseError;

    if (error.statusCode === 401) {
      removeAuthToken();
      window.location.reload();
    }
  }
});

export const client = new ApolloClient({
  link: ApolloLink.from([removeTypenameLink, errorLink, authLink, httpLink]),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
});
